/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/gsap@1.20.6/CSSPlugin.min.js
 * - /npm/gsap@1.20.6/TweenLite.min.js
 * - /npm/gsap@1.20.6/EasePack.min.js
 * - /npm/gsap@1.20.6/TimelineLite.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
